<template>
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title" data-aos="zoom-in" data-aos-delay="100">
        <h2>About Us</h2>
        <p>Siamo un team di professionisti esperti che unisce competenza,
          creatività e dedizione per offrire servizi di
          qualità ai nostri clienti. </p>
      </div>
      <div class="row">
        <div class="col-lg-6 pt-4 ">
          <div class="row container content align-items-center" data-aos="zoom-in" data-aos-delay="100">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <ul>
              <li><i class="ri-check-double-line"></i>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat
              </li>
              <li><i class="ri-check-double-line"></i>
                Duis aute irure dolor in reprehenderit in voluptate velit
              </li>
              <li><i class="ri-check-double-line"></i>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat
              </li>
            </ul>
            <div class="text-center mt-3 col-12">
              <a type="button" href="#contact">Contact Us</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 pt-4" data-aos="zoom-in" data-aos-delay="100">
          <img src="assets/img/aboutUs/prova.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>
</style>
