<template>
  <section id="info" class="info">
    <div class="container" data-aos="fade-up">
      <!--div class="section-title">
        <h2>Esplora le funzionalità potenti di AmicoPOS</h2>
      </div-->
      <div class="row">
        <div class="col-lg-6 col-sm-12 video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
          <img src="assets/img/info/info.png" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 mt-5 col-sm-12 text-center text-justify">
          <h1>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga Necessitatibus eius consequatur ex aliquid fuga
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style scoped>

</style>