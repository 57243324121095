import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp, faBars, faRemove, faShoppingBag, faSearch, faHeart as faHeartS} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
library.add(faAngleUp, faBars, faRemove, faShoppingBag, faHeart, faHeartS, faSearch);

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).mount('#app')