<template>
  <section id="about-product" class="about-video">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Esplora le potenti funzionalità di AmicoPOS</h2>
      </div>
      <div class="row">
        <div class="col-lg-6 video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
          <img src="assets/img/product/ipad2.png" class="img-fluid" alt="">

        </div>
        <div class="col-lg-6 pt-3 pt-lg-0 content " data-aos="fade-left" data-aos-delay="100">
          <p class="fst-italic text-justify">
            Cosa rende AmicoPOS così speciale?
          </p>
          <ul>
            <li><i class="bx bx-check-double"></i>Facilità d'uso: AmicoPOS è stato sviluppato con un'interfaccia
              intuitiva e user-friendly.
            </li>
            <li><i class="bx bx-check-double"></i> Velocità nelle transazioni: AmicoPOS è stato progettato per essere
              veloce ed efficiente. Con pochi clic, potrete registrare le vendite, calcolare il resto, emettere
              scontrini o fatture e gestire i pagamenti in modo rapido e preciso.
            </li>
            <li><i class="bx bx-check-double"></i> Personalizzazione e integrazioni: Sappiamo che ogni attività è unica.
              Ecco perché AmicoPOS offre la flessibilità di personalizzare il software in base alle vostre esigenze
              specifiche.
            </li>
          </ul>
          <p>
            AmicoPOS è un software di punto vendita all'avanguardia progettato per semplificare e migliorare
            l'esperienza dei gestori di attività commerciali di ogni tipo. Sia che gestiate un negozio di abbigliamento,
            un ristorante, una caffetteria o un negozio di elettronica, AmicoPOS è il partner ideale per ottimizzare le
            operazioni quotidiane e far crescere il vostro business.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutProduct"
}
</script>

<style scoped>
</style>