<template>
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
      <Menu/>
      <h1 class="logo"><a href="index.html">Amico<strong>POS</strong></a></h1>
      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto" href="#hero">Home</a></li>
          <li><a class="nav-link scrollto" href="#services">Servizi</a></li>
          <!--li><a class="nav-link scrollto" href="#pricing">Prezzi</a></li-->
          <!--<li><a class="nav-link scrollto" href="#team">Team</a></li>-->
          <li><a class="nav-link scrollto" href="#about">Chi siamo</a></li>
          <li><a class="nav-link scrollto" href="#FAQ">FAQ</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contatti</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  name: "Header",
  components: {
    Menu
  }
}
</script>

<style scoped>


.scrollto{
  font-size: 18px;
}
</style>
