<template>
  <footer id="footer">
    <div class="footer-top text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12 footer-contact text-center">
            <h3>AmicoPOS</h3>
            <b>Un marchio di Saturno Consulting Srl</b><br>
            <p>
              Via Cascina 6, 67100 L'Aquila AQ<br><br>
              <strong>Phone:</strong> 366 1470797 / 347 8313815<br>
              <strong>Email:</strong> info@amicopos.com<br>
              <strong>P.IVA</strong> 02009750668
            </p>
          </div>
        </div>
      </div>
      <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
          <div class="copyright">
            &copy; Copyright <strong><span>Saturno Consulting Srl</span></strong>.
          </div>
        </div>
        <div class="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://www.instagram.com/saturno_consulting/" class="instagram"><i class="bx bxl-instagram"></i></a>
          <a href="https://www.linkedin.com/company/saturnoconsulting/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
p {
  font-size: 60px;
}
</style>