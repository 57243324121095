<template>
  <div class="bi bi-list mobile-nav-toggle" v-on:click="toggleMenu()">
  </div>
  <div class="menu-overlay" v-on:click="toggleMenu()" :class="isOpen ? 'active' : null"></div>
  <div class="lgMenu sidebarmenu" :class="isOpen ? 'active' : null">
    <div class="closemenu" v-on:click="toggleMenu()">
      <font-awesome-icon icon="remove"/>
    </div>
    <ul class="menu">
      <li>
        <a to="/" v-on:click="toggleMenu()" href="#hero">Home</a>
      </li>
      <li>
        <a to="/" v-on:click="toggleMenu()"  href="#services">Servizi</a>
      </li>
      <!--li>
        <a to="/" v-on:click="toggleMenu()"  href="#pricing">Prezzi</a>
      </li-->
      <!--<li>
        <a to="/" v-on:click="toggleMenu()"  href="#team">Team</a>
      </li>-->
      <li>
        <a to="/" v-on:click="toggleMenu()"  href="#about">Chi siamo</a>
      </li>
      <li>
        <a to="/" v-on:click="toggleMenu()"  href="#FAQ">FAQ</a>
      </li>
      <li>
        <a to="/" v-on:click="toggleMenu()"  href="#contact">Contatti</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen ? this.isOpen = false : this.isOpen = true;
    },
  }
}
</script>

<style scoped>

a {
  flex: 1;
  background-color: white;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-out;
}

a:hover,
  a:focus {
    background-color: rgba(229, 228, 226, 0.5);;

  }


.sidebarmenu{
  overflow: scroll;
}

.btn-menu svg {
  font-size: 28px;
}

.btn-menu svg:hover {
  cursor: pointer;
}

.menu-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: none;
}

.sidebarmenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
}

.menu {
  padding: 10px !important;
  position: relative;
  display: block;
  list-style: none;
  overflow: hidden;
}

.menu li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dedede;
  margin-left: 0 !important;
}

.menu li a {
  display: block;
  padding: 20px;
  text-align: left;
  color: #000;
  font-size: 17px;
}

.closemenu {
  text-align: center;
  padding: 50px;
  color: #0b5ed7;
}

.closemenu svg {
  font-size: 30px;
}

.closemenu:hover {
  cursor: pointer;
  color: darkblue;
}


@media (max-width: 768px) {
  .btn-menu {
    padding-top: 35px;
  }
}

.menubottom li{
  border: none;
}

.menubottom li input{
  border: 1px solid #dedede;
}

.menutop svg{
  font-size: 25px;
}

.active {
  display: block !important;
}

</style>