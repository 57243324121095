<template>
  <Discover/>
  <AboutProduct/>
  <Gallery/>
  <Services/>
  <Info/>
  <!--<Team/>-->
  <AboutUs/>
  <FAQ/>
  <!--Pricing/-->
  <Contact/>
</template>


<script>
import ScrollTop from "@/components/ScrollTop";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Discover from "@/components/Discover";
import AboutUs from "@/components/AboutUs";
import Services from "@/components/Services";
import Pricing from "@/components/Pricing";
import Team from "@/components/Team";
import Contact from "@/components/Contact";
import AboutProduct from "@/components/AboutProduct";
import Clients from "@/components/Clients";
import FAQ from "@/components/FAQ";
import Gallery from "@/components/Gallery";
import Info from "@/components/Info";

export default {
  name: 'HomeView',
  components: {
    Info,
    Gallery,
    FAQ,
    Clients,
    AboutProduct,
    ScrollTop,
    Footer,
    Header,
    Discover,
    AboutUs,
    Services,
    Pricing,
    Team,
    Contact
  }
}
</script>
