<template>
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Contatti</h2>
        <p>Contattateci oggi stesso per una demo gratuita o per maggiori informazioni sulle funzionalità e i piani di
          abbonamento di AmicoPOS. </p>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>A108 Adam Street, New York, NY 535022</p>
            </div>
            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>info@example.com</p>
            </div>
            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+1 5589 55488 55s</p>
            </div>
          </div>
        </div>
        <div class="col-lg-8 mt-5 mt-lg-0">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row gy-2 gx-md-3">
              <div class="col-md-6 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name"
                       required>
              </div>
              <div class="col-md-6 form-group">
                <input type="email" class="form-control" name="email" id="email"
                       placeholder="Your Email" required>
              </div>
              <div class="form-group col-12">
                <input type="text" class="form-control" name="subject" id="subject"
                       placeholder="Subject" required>
              </div>
              <div class="form-group col-12">
                                <textarea class="form-control" name="message" rows="5" placeholder="Message"
                                          required></textarea>
              </div>
              <div class="my-3 col-12">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center col-12">
                <button type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>