<template>
  <div className="scroll-top" v-on:click="scrollToTop" v-if="scrolled">
    <font-awesome-icon icon="angle-up" size="lg"/>
  </div>
</template>
<script>
export default {
  name: 'ScrollTop',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrolled: false
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
</script>

<style scoped>
.scroll-top {
  width: 50px;
  height: 50px;
  background-color: dodgerblue;
  position: fixed;
  bottom: 16px;
  right: 20px;
  z-index: 9999;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

}

.scroll-top:hover {
  cursor: pointer;
}

.scroll-top svg {
  position: absolute;
  top: 15px;
  left: 16px;
}

</style>
