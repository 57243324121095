<template>
  <section class="gallery" id="gallery">
    <div class="container">
      <div class="row mt-3">
        <div class="col-12">
        </div>
        <div class="col-md-6 col-lg-4 " data-aos="zoom-in" data-aos-delay="100">
          <div class="card mt-5">
            <img src="assets/img/product/cassa1.jpg" class="card-image-top" alt="thumbnail">
          </div>
        </div>
        <div class="col-md-6 col-lg-4" data-aos="zoom-in" data-aos-delay="110">
          <div class="card">
            <img src="assets/img/product/cassa2.jpg" class="card-image-top" alt="thumbnail">
          </div>
        </div>
        <div class="col-md-6 col-lg-4" data-aos="zoom-in" data-aos-delay="120">
          <div class="card mt-5">
            <img src="assets/img/product/cassa3.jpg" class="card-image-top" alt="thumbnail">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.gallery {
  padding-top: 20px !important;
}
</style>
