<template>
  <div>
    <Spinner/>
    <Header/>
    <HomeView/>
    <Footer/>
    <ScrollTop/>
  </div>
</template>

<script>
import HomeView from "@/views/HomeView";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ScrollTop from "@/components/ScrollTop";
import Spinner from "@/components/Spinner";

export default {
  components: {ScrollTop, Footer, Header, HomeView,Spinner}
}
</script>
